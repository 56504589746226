import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"
import Address from "./Address";
import logo from "../../static/logo.svg";
import facebook from "../../static/facebook.svg";
import twitter from "../../static/twitter.svg";
import instagram from "../../static/instagram.svg";
import safe from "../../static/safe.png";
import logoForestaEs from "../../static/logo-foresta-es.png";
import logoForestaEn from "../../static/logo-foresta-en.png";
import { setPathSlash } from "./lib/utils";
import * as CookieConsent from "vanilla-cookieconsent";


class PageFooter extends Component {
  render() {
	const { t } = this.props;
	const language = this.props.i18n.language;
	const cookieConsentShow = () => {
		CookieConsent.showPreferences();
	}

	return <footer className="pt-5 pb-3">
		<div className="container">
			<div className="row">
				<StaticQuery query={graphql`
					query FooterQuery {
						site {
							siteMetadata {
								addresses {
									styleClass,
									name,
									address,
									phonetext,
									phone,
									whatsapptext,
									whatsapp,
									email,
									map
								}
								facebook
								twitter
								instagram
								footer {
									name
									styleClass
									articles {
										name
										article
										btn
									}
								}
							}
						}
						allLocalizedArticle {
							edges {
								node {
									articleId
									name
									path
									lang
								}
							}
						}
						allContentResource {
							nodes {
								name
								lang
								remoteFile {
									publicURL
								}
							}
						}
					}
				`}
				render={data => (<>
					<div className="col-lg-3 my-2 my-md-0 font-weight-lighter text-center text-xl-left">
						<div className="row">
							<div className="col col-xl-9 col-xxl-8 logo">
								<img src={logo} alt={t("logo")}></img>
								<span className="d-block pre-line my-2">{t("derechos")}</span>
								<div>
									<div className="redes">
										<a href={data.site.siteMetadata.facebook} target="_blank" rel="noreferrer"><img src={facebook} alt={t("facebook")}></img></a>
										<a href={data.site.siteMetadata.twitter} target="_blank" rel="noreferrer"><img src={twitter} alt={t("twitter")}></img></a>
										<a href={data.site.siteMetadata.instagram} target="_blank" rel="noreferrer"><img src={instagram} alt={t("instagram")}></img></a>
									</div>
									<div>
										<img className="safe" src={safe} alt={t("safe")}></img>
										<img className="safe" src={language === 'es' ? logoForestaEs : logoForestaEn} alt={t("foresta")}></img>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr className="d-lg-none"></hr>
					{data.site.siteMetadata.addresses.map(map => (
						<Address address={map} key={map.name} />
					))}
					<hr className="d-lg-none"></hr>
					{data.site.siteMetadata.footer.map(section => (
						<div className={section.styleClass} key={section.name}>
							<span className="font-weight-bold m-0">{t(section.name)}</span>
							<ul className="list-unstyled m-0">
								{
									section.articles.map(link => {
										let linkPath;
										let linkName;
										if (link.article) {
											const article = data.allLocalizedArticle.edges.find(article => (article.node.articleId === link.article) && (article.node.lang === language))
											if (article) {
												linkPath = setPathSlash(article.node.path);
												linkName = article.node.name;
											} else {
												return <React.Fragment key={link.name}/>
											}
										} else if (link.resource) {
											const resource = data.allContentResource.nodes.find(resource => ((resource.name === link.resource) && (resource.lang === language)));
											if (resource) {
												linkPath = resource.remoteFile.publicURL;
											} else {
												return <React.Fragment key={link.name}/>
											}
										} else if (link.btn) {
											return <li key={link.name}><button type="button" className={"clic_menu_footer"} onClick={cookieConsentShow}>{t(link.name)}</button></li>
										} else if (link.url) {
											return <li key={link.name}><a href={link.url}>{link.name}</a></li>
										} else {
											return <li key={link.name}>{t(link.name)}</li>
										}
										return <li key={link.name}><Link to={setPathSlash(linkPath)}>{linkName}</Link></li>
									})
								}
							</ul>
						</div>
					))}
				</>
				)}/>
			</div>
		</div>
	</footer>;
  }
}

export default withTranslation('PageFooter')(PageFooter);

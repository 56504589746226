import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';

function call(phone) {
  return <a href={"tel:" + phone.replace(/ /g, '')}>{phone}</a>;
}


function mailto(email) {
  return <a href={"mailto:" + email}>{email}</a>;
}

class Address extends Component {

  render() {
    const { t } = this.props;

    return <div className={this.props.address.styleClass}>
      <span className="font-weight-bold m-0">{t(this.props.address.name)}</span>
      <ul className="list-unstyled m-0">
        <li key={this.props.address.address} className="pre-line">
          {t(this.props.address.phonetext)} {call(t(this.props.address.phone))}<br/>
          {t(this.props.address.whatsapptext)}<br/> {call(t(this.props.address.whatsapp))}<br/>
          {mailto(t(this.props.address.email))}
          <address className="m-0">{t(this.props.address.address)}</address>
        </li>
        <li key={this.props.address.address + "_map"}>
          <a className="map" href={this.props.address.map} target="_blank" rel="noreferrer">{t("encuentranos")}</a>
        </li>
      </ul>
    </div>
  }
}

export default withTranslation('Address')(Address);

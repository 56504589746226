/**
 * Método que parsea los tipos de moneda que nos llegan del servidor al símbolo de dicha moneda.
 * Si nos llega una moneda desconocida, mostramos lo que llega
 *
 * @param {string} locale 
 * @param {float} value
 * @param {string} currency 
 */
let parseCurrency = function(locale, value, currency) {
    const formatter = Intl.NumberFormat(locale, {style:'currency', currency: currency});
    return formatter.format(value);
}

exports.parseCurrency = parseCurrency;

/**
 * Método Set Path Slash
 *
 * @param {string} path
 */
const setPathSlash = (path) => {

    if (path === undefined || path === null) {
        return null;
    }
    let result;
    if (path === ''
      || path.indexOf('?') !== -1
      || path.charAt(path.length-1) === '/'
    ) {
        result = path;
    } else if (path.indexOf('#') !== -1
      && path.charAt(path.length-1) === '/'
    ) {
        result = path.slice(0, -1);
    }  else if (path.indexOf('#') !== -1
      && path.charAt(path.length-1) !== '/'
    ) {
        result = path;
    } else {
        result = path + '/';
    }

    result = result.replace(' ', '');

    return result;
}

exports.setPathSlash = setPathSlash;

/**
 * Método para url canonical
 * @param {string} path
 */
const setPathCanonical = (path) => {
    if (path === undefined || path === null) {
    return null;
    }

    const init = path.indexOf('#');
    if (init !== -1) {
      return path.slice(0, init);
    }

    return path;
}

exports.setPathCanonical = setPathCanonical;
import React from "react";
import { Link as I18nLink, withTranslation } from 'gatsby-plugin-react-i18next';
import LangSelector from '../components/LangSelector';
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import logo from "../../static/logo.svg";
import { setPathSlash } from "./lib/utils";


class NavigationBar extends React.Component {
	
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    const { t } = this.props;
	const language = this.props.i18n.language;
	const onClickMenu = (buttonLocation) => {
		window.addDataLayer({
			event: "menu_superior_click",
			button_location: buttonLocation
		});
	}

		return <React.Fragment>
			 <Helmet>
			
			 </Helmet>
			<div className="fixed-top bg-white shadown-bottom">
				<nav className="navbar navbar-expand-xl navbar-light bg-white navigation-bar px-2 py-2 py-xl-4 container-principal">
					<Link className="navbar-brand pl-2" to={`/${language}/`} aria-label="Tamarán"><img src={logo} alt={t("logo")}/></Link>
					<LangSelector></LangSelector>
					<button className="navbar-toggler" type="button"
						data-toggle="collapse" data-target="#navbarPrincipal"
						aria-controls="navbarPrincipal" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon" />
					</button>
					<div className="collapse navbar-collapse" id="navbarPrincipal">
						<ul className="navbar-nav ml-xxl-5 text-center">
							<StaticQuery query={graphql`
								query SiteTitleQuery {
									site {
										siteMetadata {
											menuLinks {
												name
												section
												url
												article
												children {
													name
													article
													anchor
													experience
													lowercaseText
													hidden
												}
											}
										}
									}
									allLocalizedArticle(sort: {fields: [section, lang, order], order: ASC}) {
										edges {
											node {
												articleId
												name
												path
												lang
												section
											}
										}
									}
									allExperience {
										edges {
											node {
												experienceId
												path
												lang
												name
											}
										}
									}
								}
							`}
							render={data => (
								data.site.siteMetadata.menuLinks.map(link => {
									let linkPath;
									if (link.path) {
										return <li className="nav-item" key={link.name}>
											<I18nLink className="nav-link" to={setPathSlash(link.path)} language={language}>
												<span>{t(link.name)}</span>
											</I18nLink>
										</li>
									} else if (link.article) {
										const article = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === link.article) && (article.node.lang === language)));
										if (article) {
											linkPath = article.node.path;
										} else {
											return <React.Fragment key={link.name}/>
										}
									} else if (link.experience) {
										const experience = data.allExperience.edges.find(experience => ((experience.node.experienceId === link.experience) && (experience.node.lang === language)));
										if (experience) {
											linkPath = experience.node.path;
										} else {
											return <React.Fragment key={link.name}/>
										}
									} else if (link.section) {
										return <li className={"nav-item dropdown ml-xl-3 ml-xxl-4"} key={link.name}>
											<button className="nav-link dropdown-toggle dropdown-toggle-rojo" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span className="text-uppercase">{t(link.name)}</span>
												<i className="fa fa-angle-down ml-1 small d-none d-xl-inline" aria-hidden="true"></i>
											</button>
											<div className={"dropdown-menu text-center"} aria-labelledby="navbarDropdown">
												<span className="dropdown-menu-arrow"></span>
												{link.children.map((item, i) => {
													if (item.article) {
														const thisArticle = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === item.article) && (article.node.lang === language)));
														if (thisArticle) {
															let linkPath = thisArticle.node.path;
															if (item.anchor && item.anchor !== 'true') {
																linkPath += `#${item.anchor}`;
															}
															return (<React.Fragment key={i}>
																<div className={`dropdown-divider dropdown-divider-dotted ${item.anchor ? '' : 'd-none'}`}></div>
																<Link className={`dropdown-item ${item.anchor ? 'dropdown-destacado' : ''}`}
																			to={setPathSlash(linkPath)}
																			onClick={() => {onClickMenu(thisArticle.node.path.split('/').slice(-2)[0]);}}
																>
																	{item.lowercaseText === true ? <span>{t(item.name)}</span> : <span className="text-uppercase">{t(item.name)}{item.lowercaseText}</span>}
																</Link>
																<div className={`dropdown-divider dropdown-divider-dotted ${item.anchor ? '' : 'd-none'}`}></div>
															</React.Fragment>);
														} else {
															return null;
														}
													} else if (item.experience) {
														const thisExperience = data.allExperience.edges.find(experience => ((experience.node.experienceId === item.experience) && (experience.node.lang === language)));
														if (thisExperience && !item.hidden) {
															return (<React.Fragment key={i}>
																<Link className={"dropdown-item"} to={setPathSlash(thisExperience.node.path)} onClick={() => {onClickMenu(thisExperience.node.path.split('/').slice(-2)[0]);}} >
																	<span>{t(item.name)}</span>
																</Link>
															</React.Fragment>);
														} else {
															return null;
														}
													} else {
														return null;
													}
												})}
											</div>
											<div className='dropdown-divider dropdown-divider-dotted m-0 d-xl-none'></div>
										</li>;
									} else if (link.url) {
										return <li className="nav-item ml-xl-3 ml-xxl-4 text-uppercase" key={link.name}>
											<a className="nav-link" href={setPathSlash(link.url)} target="_blank" rel="noreferrer">{t(link.name)}</a>
											<div className='dropdown-divider dropdown-divider-dotted m-0 d-xl-none'></div>
										</li>
									} else {
										return <React.Fragment key={link.name}/>
									}
									return <li className="nav-item ml-xl-3 ml-xxl-4 text-uppercase" key={link.name}>
										<Link className="nav-link" to={setPathSlash(linkPath)} onClick={() => {onClickMenu(link.name);}} >
											<span>{t(link.name)}</span>
										</Link>
										<div className='dropdown-divider dropdown-divider-dotted m-0 d-xl-none'></div>
									</li>
									}
								))}
							/>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>;
  }
}

export default withTranslation('NavigationBar')(NavigationBar);

import  { useContext, useEffect } from "react";
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import * as CookieConsent from "vanilla-cookieconsent";
import { useCookiesData } from "../hooks/cookies-data"; // Asegúrate de actualizar esta ruta si es necesario

const CookieValidation = () => {
	const {siteMetadata} = useCookiesData();
	const context = useContext(I18nextContext);
	const language = context.language || 'es';
	const { gtmTrackingId } = siteMetadata;

	const termsAndConditionsArticleInfo = siteMetadata.termsAndConditionsArticleInfo.find(article => article.lang === language);
	const termsConditionsUrl = termsAndConditionsArticleInfo ? termsAndConditionsArticleInfo.path : '';
	const cookiePolicyArticleInfo = siteMetadata.cookiesPolicyArticleInfo.find(article => article.lang === language);
	const cookiesPolicyUrl = cookiePolicyArticleInfo ? cookiePolicyArticleInfo.path : '';
	const translations = siteMetadata.cookies.translations;
	translations[language].consentModal.footer = translations[language].consentModal.footer
		.replace("#link_cookies", cookiesPolicyUrl);
	translations[language].consentModal.footer = translations[language].consentModal.footer
		.replace("#link_terms", termsConditionsUrl);


	useEffect(() => {
		const activateAnalytics = () => {
			CookieConsent.loadScript('//www.google-analytics.com/analytics.js').then(() => {
				console.log("load Google Tag Manager");
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js'
				});
				CookieConsent.loadScript(`//www.googletagmanager.com/gtm.js?id=${gtmTrackingId}`)
					.then(() => {
						console.log("load Google Tag Manager");
					});
			});
		}

		const deactivateAnalytics = () => {
			window.location.reload();
		}

		CookieConsent.run({
			disablePageInteraction: true,
			guiOptions: {
				consentModal: {
					layout: "box wide",
					position: "bottom center",
					equalWeightButtons: true,
					flipButtons: false,
				},
				preferencesModal: {
					layout: "box",
					position: "right",
					equalWeightButtons: true,
					flipButtons: false
				}
			},
			categories: {
				necessary: {
					readOnly: true
				},
				functionality: {},
				analytics: {},
				social: {}
			},
			language: {
				default: "es",
				autoDetect: "browser",
				translations: translations
			},
			onFirstConsent: ({ cookie }) => console.log("Primer consentimiento:", cookie),
			onConsent: ({ cookie }) => {
				activateAnalytics()
			},
			onChange: ({ cookie, changedCategories, changedServices }) => {
				if(!CookieConsent.acceptedCategory('analytics') &&
					!CookieConsent.acceptedCategory('social') &&
					!CookieConsent.acceptedCategory('functionality')
				){
					deactivateAnalytics();
				}
			},

		});
		CookieConsent.setLanguage(language, true);
	}, [language, gtmTrackingId, translations]);


	return null;
};

export default CookieValidation;

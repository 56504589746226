import React from 'react'
import NavigationBar from '../components/NavigationBar'
import PageFooter from '../components/PageFooter'
import CookieValidation from './CookieValidation'
import flechaArriba from "../../static/flecha-arriba.svg";

export default class Layout extends React.Component {

	toggleBotonSubir() {
		if (window.scrollY > 250) {
			window.$(".boton-subir").css('display', 'flex');
		} else {
			window.$(".boton-subir").hide();
		}
	}

	goUp() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		this.toggleBotonSubir();
		window.addEventListener('scroll', () => {
			this.toggleBotonSubir();
		});
	}

  render() {
		return <>
			<NavigationBar />
			<main>
				{this.props.children}
			</main>
			<div className="boton-subir"
					 role="button" tabIndex={0}
					 onClick={() => this.goUp()}
					 onKeyDown={() => null}>
				<img src={flechaArriba} aria-hidden={true} alt="Subir"></img>
			</div>
			<PageFooter/>
			<CookieValidation/>
		</>
	}
}

import React from "react"
import { Link as I18nLink, I18nextContext, withTranslation } from 'gatsby-plugin-react-i18next';
import { Location } from "@reach/router"
import ContentContext from "../context/ContentContext";
import { Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class LangSelector extends React.Component {

	static contextType = I18nextContext;

	render() {
		const language = this.props.i18n.language;
		const languageStr = {
			"es": "Español",
			"en": "English",
			"de": "Deutsch",
			"fr": "Français",
			"it": "Italiano",
			"nl": "Nederlands",
			"pl": "Polski"
		}
		const onClickLanguage = (buttonLocation) => {
			if (typeof window !== `undefined`){
				window.addDataLayer({
					event: "click_seleccion_idioma",
					selected_language: buttonLocation
				});
			}
		}


		return <div className="navbar-nav languages nav-item dropdown" key="languages">
			<button className="nav-link dropdown-toggle dropdown-toggle-square bg-white" id="navbarLanguagesDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<span className="text-uppercase">{language}</span>
			</button>
			<div className="dropdown-menu dropdown-menu-right dropdown-menu-languages text-center p-0" aria-labelledby="navbarLanguagesDropdown">
				<ContentContext.Consumer>{content => {
					if (content.translations) {
						return this.context.languages.map((lng) => {
							const translation = content.translations.find(translation => (translation.lang === lng));
							if (translation) {
								return <li className="nav-item" key={lng}>
									<Link
										to={setPathSlash(translation.path)}
										className="nav-link"
										onClick={() => onClickLanguage(lng)}
									>
										{lng}
									</Link>
								</li>
							} else {
								return <React.Fragment key={lng}/>
							}
						});
					} else {
						return <Location>
							{({ location }) => {
								const sinIdioma = location.pathname.replace("/" + this.context.language, "");
								return this.context.languages.map((lng, i) => {
									if (lng !== language) {
										return <li className="nav-item" key={lng}>
											<I18nLink
												to={setPathSlash(sinIdioma + location.search) || ''}
												language={lng}
												className="nav-link px-3 py-2"
												onClick={() => onClickLanguage(lng)}
											>
												{languageStr[lng]}
											</I18nLink>
											<div className={`dropdown-divider dropdown-divider-dotted m-0 ${this.context.languages.length === i + 1 ? 'd-none' : ''}`}></div>
										</li>
									} else {
										return null;
									}
								})}}
						</Location>
					}
				}}
				</ContentContext.Consumer>
			</div>
		</div>
	}
}

export default withTranslation('LangSelector')(LangSelector);

